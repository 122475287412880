import {GenericApi, IWrapperResponse} from '@/api/generic';
import {IPagination} from '@/utils/api-tools/pagination';
import {IPaginationResponse} from '@/utils/api-tools/pagination-response';
import {ISearchCriterion} from '@/utils/api-tools/search-criteria';
import {http, url} from '@/utils/http';
import {IPlayground, IPoleConfig} from '@/model/Playground';
import {base64toBlob} from '@/utils/strings';
import {IQuotation} from '@/model/Quotation';
import {ResultData} from '@/model/2d/ResultData';
import {AxiosError} from 'axios';

export class PlaygroundApi {
    public async getPlaygrounds(pagination: IPagination, search: ISearchCriterion[]):
        Promise<IWrapperResponse<IPaginationResponse<object>>> {
        return new GenericApi<object>('/api/v1/playground').list(pagination, search);
    }

    public async getPlaygroundTemplates(pagination: IPagination, search: ISearchCriterion[]):
        Promise<IWrapperResponse<IPaginationResponse<object>>> {
        return new GenericApi<object>('/api/v1/playground/templates').list(pagination, search);
    }

    public async getPlayground(playgroundId: number):
        Promise<IWrapperResponse<object>> {
        return new GenericApi<object>('/api/v1/playground').get(playgroundId);
    }

    public async partList(playgroundId: number) {
        const uri = new URL(url(`/api/v1/playground/${playgroundId}/part-list`));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<IQuotation>;
    }

    public async getPoleConfig() {
        const uri = new URL(url(`/api/v1/playground/pole-config`));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<IPoleConfig>;
    }

    public async getCertificationCostsConfig() {
        const uri = new URL(url(`/api/v1/playground/certification-costs-config`));
        const result = await http.get(uri.href);
        return result.data as IWrapperResponse<number>;
    }

    public async createPlayground(playgroundExport: IPlayground) {
        return new GenericApi<IPlayground>('/api/v1/playground').create(playgroundExport);
    }

    public async savePlayground(playgroundExport: object) {
        return new GenericApi<object>('/api/v1/playground').save(playgroundExport);
    }

    public async deletePlayground(playgroundId: number) {
        new GenericApi<object>('/api/v1/playground').delete(playgroundId);
    }

    public async saveIcon(playgroundId: number, dataUrl: string) {
        const formData = new FormData();
        const data = dataUrl.replace(/^data:image\/(png|jpg);base64,/, '');
        const blob = base64toBlob(data, 'image/png');
        formData.append('file', blob, 'icon.png');
        return http.post(url(`/api/v1/playground/${playgroundId}/icon`), formData);
    }

    public async validate(playgroundId: number, data: any) {
        return http.post(url(`/api/v1/admin/playground/${playgroundId}/validate`), data);
    }

    public async generatePdf(playgroundId: number, icon: string, image1: string, image2: string, image3: string,
                             sideImage1: string, sideImage2: string,
                             poleImage: string, elementImage: string, poleResultData: ResultData,
                             sizeData: ResultData, playgroundheight: number, playgroundFallheight: number ) {
        const formData = new FormData();
        const iconBlob = base64toBlob(icon.replace(/^data:image\/(png|jpg|jpeg);base64,/, ''),
            'image/png');
        formData.append('icon', iconBlob, 'icon.png');

        const image1Blob = base64toBlob(image1.replace(/^data:image\/(png|jpg|jpeg);base64,/, ''),
            'image/png');
        formData.append('image1', image1Blob, 'image1.png');
        const image2Blob = base64toBlob(image2.replace(/^data:image\/(png|jpg|jpeg);base64,/, ''),
            'image/png');
        formData.append('image2', image2Blob, 'image2.png');
        const image3Blob = base64toBlob(image3.replace(/^data:image\/(png|jpg|jpeg);base64,/, ''),
            'image/png');
        formData.append('image3', image3Blob, 'image3.png');

        const sideImage1Blob = base64toBlob(sideImage1.replace(/^data:image\/(png|jpg|jpeg);base64,/, ''),
            'image/png');
        formData.append('sideImage1', sideImage1Blob, 'sideImage1.png');
        const sideImage2Blob = base64toBlob(sideImage2.replace(/^data:image\/(png|jpg|jpeg);base64,/, ''),
            'image/png');
        formData.append('sideImage2', sideImage2Blob, 'sideImage2.png');

        const poleImageBlob = base64toBlob(poleImage.replace(/^data:image\/(png|jpg|jpeg);base64,/, ''),
            'image/png');
        formData.append('poleImage', poleImageBlob, 'poleImage.png');
        const elementImageBlob = base64toBlob(elementImage.replace(/^data:image\/(png|jpg|jpeg);base64,/, ''),
            'image/png');
        formData.append('elementImage', elementImageBlob, 'elementImage.png');

        formData.append('poleData',
            JSON.stringify({poleData: poleResultData.poleData, floorData: poleResultData.floorData}));

        formData.append('sizeData',
            JSON.stringify({xAxisFallSpace: sizeData.xAxisFallSpace, xAxisPlaygroundSize: sizeData.xAxisPlaygroundSize,
                yAxisFallSpace: sizeData.yAxisFallSpace, yAxisPlaygroundSize: sizeData.yAxisPlaygroundSize,
                playgroundFallHeight: playgroundFallheight, playgroundHeight: playgroundheight}));
        const response = await http.post(url(`/api/v1/admin/playground/${playgroundId}/pdf`), formData);
        if (response instanceof AxiosError) {
            console.error('Error while generating manual:', response!.response!.data.data);
        }
        return response;
    }
}
